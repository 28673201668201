import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, {useEffect, useState} from "react";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Spinner from "react-bootstrap/Spinner";
import {Button, Col} from "react-bootstrap";
import {CSVLink} from 'react-csv'
import moment from "moment";
import IndexComponentsHeader from "./IndexComponentsHeader";
import {Disclaimer} from "../../../assets/disclaimer/Disclaimer";


interface GraphData {
    priceDate: string;
    level: number;
    volatility: number;
}

function HistoricalPriceAndVolatility(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [graphData, setGraphData] = useState<GraphData[]>([]);
    const [timePeriod, setTimePeriod] = useState('5Y');
    const radios = [
        {name: 'All Time', value: 'AT'},
        {name: '5Y', value: '5Y'},
        {name: '1Y', value: '1Y'},
        {name: '6M', value: '6M'},
        {name: '3M', value: '3M'},
        {name: '1M', value: '1M'}
    ];


    function formatXAxis(tickItem) {
        return moment(tickItem).format('DDMMMYY')
    }

    function handleTime(e) {
        setTimePeriod(e.target.id);
    }

    function isTimeActive(data, value) {
        var active = data === value ? "btn-primary active" : "btn-outline-secondary";
        return active;
    }

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/graphData')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setGraphData(result);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);


    function getMonthsFromPeriod(period: string) {
        switch (period) {
            case '5Y':
                return 60;
            case '1Y':
                return 12;
            case '6M':
                return 6;
            case '3M':
                return 3;
            case '1M':
                return 1;
            default:
                return graphData.length;
        }
    }


    function getGraphData(data, period) {
        const displayPeriodInMonths = getMonthsFromPeriod(period);
        if (data.length > 0) {
            const latestPriceDate = moment(data.slice(-1)[0]['priceDate'])
            for (let counter = data.length - 1; counter >= 0; counter--) {
                let currentDate = moment(data[counter]['priceDate']);
                if (latestPriceDate.diff(currentDate, 'months') === displayPeriodInMonths) {
                    return data.slice(counter, data.length);
                }
            }
        }
        return data;
    }

    function exportFilesHeaders() {
        return [{label: "Date", key: "priceDate"}, {label: "Level", key: "level"}, {label: "Volatility", key: "volatility"}]
    };

    const ExportReactCSV = ({csvData, fileName}) => {
        let columnA = exportFilesHeaders()[0].key;
        let disclaimerMap = Disclaimer.map((data) => {
            let returnObj = {};
            returnObj[columnA] = data;
            return returnObj;
        });
        let dataWithDisclaimer = [...csvData, ...disclaimerMap];
        return (
            <CSVLink data={dataWithDisclaimer} filename={fileName}  headers={exportFilesHeaders()}>
                <Button variant="outline-secondary" className="ml-3">
                    Export
                </Button>
            </CSVLink>
        )
    }

    return (
        <Col>
            <IndexComponentsHeader title={"Historical Price and Volatility"}/>
            <Row>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center">
                        <Spinner animation="border" role="status" variant="light">
                        </Spinner>
                    </Container>
                    :
                    <>
                        <Container fluid className="d-flex align-items-center justify-content-center  mb-3">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons"
                                 onChange={handleTime}>
                                {radios.map((radio, idx) => (
                                    <label className={"btn " + isTimeActive(timePeriod, radio.value)}>
                                        <input key={idx} type="checkbox" id={radio.value}/> {radio.name}
                                    </label>
                                ))}
                            </div>
                            <ExportReactCSV csvData={graphData} fileName={'IndexLevelsAndVolatility.csv'}/>
                        </Container>
                        <ResponsiveContainer width="100%" height={500} className={"my-graph"}>
                            <LineChart
                                data={getGraphData(graphData, timePeriod)}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="priceDate"
                                       tickFormatter={formatXAxis}
                                       minTickGap={50}
                                />
                                <YAxis
                                    type="number"
                                    dataKey="level"
                                    yAxisId="left"
                                />
                                <YAxis yAxisId="right"
                                       type="number"
                                       dataKey="volatility" orientation="right"
                                />
                                <Tooltip/>
                                <Legend/>
                                <Line name={'Level'}
                                      yAxisId="left"
                                      type="monotone" dataKey="level"
                                      stroke="blue" activeDot={{r: 4}} dot={false}/>
                                <Line name={'Volatility'} yAxisId="right" type="monotone"
                                      dataKey="volatility"
                                      stroke="red" activeDot={{r: 4}} dot={false}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </>
                }
            </Row>
        </Col>
    );
}

export default HistoricalPriceAndVolatility;
