import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import pdfLogo from "../../../assets/img/pdf.png";
import IndexComponentsHeader from "./IndexComponentsHeader";


function IndexNotices(props) {
    const [indexNotices, setIndexNotices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/notices/' + props.indexCompositeKey)
            .then(res => res.json())
            .then(
                (result) => {
                    setIndexNotices(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    function renderNoticesList(indexNotices) {
        return indexNotices.map((notice) =>
            <div className="dotted-line">
                <Row className="no-gutters" >
                    <Col style={{padding: "0px 10px"}} sm={3}>
                        <div style={{float: "left"}} className="home-notices-text-date">{notice.createdDate}</div>
                    </Col>
                    <Col style={{padding: "0px 10x"}} sm={9}>
                        <div className="home-notices-text">
                            {notice.noticeText}
                            {(notice.hasFile) ?
                                <a download={notice.fileName} target="_blank"
                                   href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + notice.id + "&documentType=" + notice.documentType}>
                                    <img alt="PDF" src={pdfLogo} width={25} height={25}/>
                                </a>
                                : <></>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <>
            {(!isLoaded)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </Container>
                :
                <>
                    {(indexNotices.length > 0)
                        ?
                        <Col>
                            <IndexComponentsHeader title={"Index Notices"}/>
                            <Row>
                                <Col>
                                    <div style={{maxHeight: "230px", overflowY: "auto"}}>
                                        {renderNoticesList(indexNotices)}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <></>
                    }
                </>
            }
        </>
    )
};

export default IndexNotices;