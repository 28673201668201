export function calculateMonthlyMeasurementsFromPriceData(levels) {
    interface PriceMeasurement {
        priceDate: string;
        level: number;
    }

    let monthlyReturnsDict = {};
    let monthlyReturnsTemp: number[] = [];
    let month = '', year = '';
    let price: number = 0;
    if (levels.length > 0) {
        levels.forEach(function (level: PriceMeasurement) {
            let currentMonth = level.priceDate.substring(4, 6);
            let currentYear = level.priceDate.substring(0, 4);
            let currentPrice = level.level;
            if (month !== '' && currentMonth !== month) {
                let missedMonths = Math.abs((parseInt(currentMonth) - parseInt(month)) % 11)
                if (missedMonths > 0) {
                    for (var j = 1; j < missedMonths; j++) {
                        monthlyReturnsTemp.unshift(price);
                    }
                }
                monthlyReturnsTemp.unshift(price);
            }
            if (year !== '' && currentYear !== year) {
                monthlyReturnsDict[year] = monthlyReturnsTemp.reverse();
                monthlyReturnsTemp = [];
            }
            month = currentMonth;
            year = currentYear;
            price = currentPrice;
        });
        monthlyReturnsTemp.unshift(price);
        monthlyReturnsDict[year] = monthlyReturnsTemp.reverse();
    }
    return monthlyReturnsDict;
}