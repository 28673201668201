import React, {useEffect, useState} from "react";
import pdfLogo from "../../assets/img/pdf.png";
import Container from "react-bootstrap/Container";
import '../../styles.css';
import HeaderComponent from "../header/HeaderComponent";
import {BsSearch} from "react-icons/bs";
import {AgGridReact} from "ag-grid-react";
import {Col, Row} from "react-bootstrap";
import {ICellRendererParams} from "ag-grid-community";
import Spinner from "react-bootstrap/Spinner";

function Publications() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [relevantIndices, setRelevantIndices] = useState([]);
    const [inputNameTickerString, setInputNameTickerString] = useState("");
    const [searchNameTicker, setSearchNameTicker] = useState("");

    function keyPress(e) {
        if (e.keyCode === 13) {
            setSearchNameTicker(inputNameTickerString);
        }
    }

    function handleClick(e) {
        e.preventDefault();
        setSearchNameTicker(inputNameTickerString);
    }

    function joinFilters() {
        let filters = "";
        if (searchNameTicker !== "") {
            filters = "?searchNameTicker=" + searchNameTicker;
        }
        return filters;
    }

    function rowSelected(row) {
        let indexIds = row.data.relevantIndices;
        setRelevantIndices(indexIds);
    }

    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/documents/publications' + joinFilters())
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setDocumentsList(result.publications);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, [searchNameTicker]);

    const [columnDefs] = useState([
        {
            field: "createdDate", headerName: "Issue Date", filter: 'agMultiColumnFilter',
            width: 70,
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "documentName",
            headerName: "Document/Notice",
            filter: 'agMultiColumnFilter',
            cellRenderer: "CellDocumentDownload",
            wrapText: true,
            autoHeight: true,
            minWidth: 700,
            cellStyle: {"white-space": "normal", "height": "auto"},
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "documentType", headerName: "Document Type", filter: 'agMultiColumnFilter', width: 70,
            filterParams: {
                filters: [
                    {
                        filter: 'agDateColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        }
    ]);
    const [defaultColDef] = useState({
        enableValue: true,
        sortable: true,
        resizable: true,
        enableCellTextSelection: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    })


    return (
        <Container style={{maxWidth: "1350px"}}>
            <HeaderComponent title={"Publications"}/>
            <div style={{width: '1370px', height: '650px', padding: "10px"}} className="usual-text ag-theme-webadk ">
                <div className="input-group input-group-md" style={{
                    width: "23%",
                    padding: "6px 7px"
                }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={event => {
                            setInputNameTickerString(event.target.value)
                        }}
                        onKeyDown={keyPress}
                        aria-describedby="inputGroup-sizing-md"
                    />
                    <div className="input-group-prepend">
                        <button className="btn btn-light" type="button" onClick={handleClick}>
                            <BsSearch/>
                        </button>
                    </div>
                </div>
                {(isLoaded) ?
                    <Row>
                        <Col style={{padding: "0px 3px", maxWidth: "80%"}}>
                            <div style={{width: '99%', height: '100%', padding: "5px", display: "inline-block"}}>
                                <AgGridReact
                                    rowData={documentsList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection='single'
                                    onRowClicked={(e) => rowSelected(e)}
                                    components={{CellDocumentDownload: CellDocumentDownloadRenderer}}>
                                </AgGridReact>
                            </div>
                        </Col>
                        <Col style={{padding: "0px 3px", maxWidth: "20%"}}>
                            <div className="relevant-indices">
                                <div className="publ-relevant-indices-header"> Relevant indices</div>
                                <div className="publ-relevant-indices-divider"></div>
                                <div>{relevantIndices.map((indexId) =>
                                    <div><a href={"indices/" + indexId["indexId"]}>{indexId['bbTicker']}</a>
                                    </div>)}</div>
                            </div>
                        </Col>
                    </Row>
                    :
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                }
            </div>
        </Container>
    )
}

const CellDocumentDownloadRenderer = (params: ICellRendererParams) => {
    return <span> {params.node.data.docText} {params.node.data.documentName != null ?
        <a download={params.value} target="_blank"
           href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + params.node.data.id + "&documentType=" + params.node.data.documentType}>
            <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20}
                 height={20}/>{params.node.data.documentType == 'Specific Notices' ? "" : params.value}
        </a> : <></>
    }
        </span>
};

export default Publications;
