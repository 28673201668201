import React, {useEffect, useState} from "react";
import {AgGridReact} from 'ag-grid-react'; // the AG Grid React Component
import '@wadk/ag-grid/dist/day.css'; // Optional theme CSS
import "../../styles.css";
import Container from "react-bootstrap/Container";
import HeaderComponent from "../header/HeaderComponent";
import {BsArrowRepeat, BsSearch} from "react-icons/bs";
import {Form} from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import {ICellRendererParams} from "ag-grid-community";
import Spinner from "react-bootstrap/Spinner";


function Indices() {
    const searchParams = new URLSearchParams(window.location.search).get("searchNameTicker");

    const [isLoaded, setIsLoaded] = useState(false);
    const [indices, setIndices] = useState([]);

    const [region, setRegion] = useState<string[]>([]);
    const [riskClass, setRiskClass] = useState<string[]>([]);
    const [inputNameTickerString, setInputNameTickerString] = useState("");
    const [searchNameTicker, setSearchNameTicker] = useState(searchParams === null ? "" : searchParams);
    const [returns, setReturns] = useState<string[]>([]);
    const [currency, setCurrency] = useState<string[]>([]);
    const [assetClass, setAssetClass] = useState<string[]>([]);

    function joinFilters() {
        let filters = "?auth=N";
        if (searchNameTicker !== "") {
            filters = filters + "&searchNameTicker=" + searchNameTicker;
        }
        if (region.length > 0) {
            for (let i = 0; i < region.length; i++) {
                filters = filters + "&region=" + region[i];
            }
        }
        if (riskClass.length > 0) {
            for (let i = 0; i < riskClass.length; i++) {
                filters = filters + "&riskClass=" + riskClass[i];
            }
        }
        if (returns.length > 0) {
            for (let i = 0; i < returns.length; i++) {
                filters = filters + "&returns=" + returns[i];
            }
        }
        if (currency.length > 0) {
            for (let i = 0; i < currency.length; i++) {
                filters = filters + "&currency=" + currency[i];
            }
        }
        if (assetClass.length > 0) {
            for (let i = 0; i < assetClass.length; i++) {
                filters = filters + "&assetClass=" + assetClass[i];
            }
        }
        return filters;
    }

    useEffect(() => {
        fetch("/dbiq-web/rest/webdata/indices" + joinFilters())
            .then(res => res.json())
            .then(
                (result) => {
                    setIndices(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, [region, riskClass, searchNameTicker, returns, currency, assetClass]);

    function keyPress(e) {
        if (e.keyCode === 13) {
            setSearchNameTicker(inputNameTickerString);
        }
    }

    function handleClick(e) {
        e.preventDefault();
        setSearchNameTicker(inputNameTickerString);
    }

    function handleClickRefresh(e) {
        window.location.href = '/dbiq-web/indices'
    }

    function handleRiskClass(e) {
        if (e.target.checked) {
            setRiskClass(prevValues => [...prevValues, e.target.id])
        } else {
            setRiskClass(riskClass.filter(item => item !== e.target.id));
        }
    }

    function handleReturns(e) {
        if (e.target.checked) {
            setReturns(prevValues => [...prevValues, e.target.id])
        } else {
            setReturns(returns.filter(item => item !== e.target.id));
        }

    }

    function handleRegion(e) {
        if (e.target.checked) {
            setRegion(prevValues => [...prevValues, e.target.id])
        } else {
            setRegion(region.filter(item => item !== e.target.id));
        }
    }

    function handleCurrency(e) {
        if (e.target.checked) {
            setCurrency(prevValues => [...prevValues, e.target.id])
        } else {
            setCurrency(currency.filter(item => item !== e.target.id));
        }
    }

    function handleAssetClass(e) {
        if (e.target.checked) {
            setAssetClass(prevValues => [...prevValues, e.target.id])
        } else {
            setAssetClass(assetClass.filter(item => item !== e.target.id));
        }
    }

    function ERReturnsActive() {
        var active = returns.some(item => "ER" === item) ? "btn-primary active" : "btn-outline-secondary";
        return active;
    }

    function PRReturnsActive() {
        var active = returns.some(item => "PR" === item) ? "btn-primary active" : "btn-outline-secondary";
        return active
    }

    function TRReturnsActive() {
        var active = returns.some(item => "TR" === item) ? "btn-primary active" : "btn-outline-secondary";
        return active
    }

    const [currencyVariants] = useState(["AED", "AUD", "BRL", "CAD", "CHF", "CNY", "DKK", "EUR", "GBP", "HKD",
        "IDR", "INR", "JPY", "KRW", "LCL", "LKR", "MXN", "MYR", "NOK", "NZD", "PHP", "PKR", "PLN", "QAR", "SAR", "SEK",
        "SGD", "THB", "TRY", "TWD", "USD", "VND", "ZAR",])
    const [regionVariants] = useState(["Americas", "Asia Pacific", "Europe", "Middle East & Africa", "Global"])

    const [columnDefs] = useState([
        {
            field: "name",
            headerName: "Index Name",
            filter: 'agMultiColumnFilter',
            cellRenderer: "CellIndexNameLink",
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "assetClass",
            headerName: "Asset Class",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "sector",
            headerName: "Sector",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "region",
            headerName: "Region",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "returnStream",
            headerName: "Risk Class",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "defaultCurrency",
            headerName: "CCY",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "bbticker",
            headerName: "BBG Ticker",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "reuters",
            headerName: "Reuters",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        },
        {
            field: "returns",
            headerName: "Returns",
            filter: 'agMultiColumnFilter',
            filterParams: {
                filters: [
                    {
                        filter: 'agTextColumnFilter',
                        display: 'subMenu',
                    },
                    {
                        filter: 'agSetColumnFilter',
                    }
                ]
            }
        }
    ]);
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    })

    return (
        <>
            {(!isLoaded)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center"
                           style={{height: '500px'}}>
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </Container>
                :
                <Container style={{maxWidth: "1350px"}}>
                    <HeaderComponent title={"Indices"}/>
                    <div style={{width: '1370px', height: '800px', padding: "10px"}} className="usual-text">
                        <div className="indices-filter-panel">
                            <div className="indices-filter-panel-section">Asset Class
                                <Form onChange={handleAssetClass}>
                                    <div>
                                        <Form.Check
                                            inline
                                            label="Equities"
                                            type='checkbox'
                                            id="Equities"
                                        />
                                        <Form.Check
                                            inline
                                            label="Commodities"
                                            type='checkbox'
                                            id="Commodities"
                                        />
                                        <Form.Check
                                            inline
                                            label="FX"
                                            type='checkbox'
                                            id="FX"
                                        />
                                        <Form.Check
                                            inline
                                            label="Credit"
                                            type='checkbox'
                                            id="Credit"
                                        />
                                        <Form.Check
                                            inline
                                            label="Fixed Income"
                                            type='checkbox'
                                            id="Fixed Income"
                                        />
                                        <Form.Check
                                            inline
                                            label="Alternative"
                                            type='checkbox'
                                            id="Alternative"
                                        />
                                        <Form.Check
                                            inline
                                            label="Multi-Asset"
                                            type='checkbox'
                                            id="Multi-Asset"
                                        />
                                        <Form.Check
                                            inline
                                            label="EM"
                                            type='checkbox'
                                            id="EM"
                                        />
                                    </div>
                                </Form>
                            </div>
                            <div className="indices-filters-divider"/>
                            <div className="indices-filter-panel-section">Risk Class
                                <Form onChange={handleRiskClass}>
                                    <div>
                                        <Form.Check
                                            inline
                                            label="Alpha"
                                            type='checkbox'
                                            id="Alpha"
                                        />
                                        <Form.Check
                                            inline
                                            label="Beta"
                                            type='checkbox'
                                            id="Beta"
                                        />
                                        <Form.Check
                                            inline
                                            label="Enhanced Beta"
                                            type='checkbox'
                                            id="Enhanced Beta"
                                        />

                                    </div>
                                </Form>
                            </div>
                            <div className="indices-filters-divider"/>
                            <div className="indices-filter-panel-section">Returns
                                <div className="btn-group btn-group-toggle" data-toggle="buttons"
                                     onChange={handleReturns}>
                                    <label className={"btn btn-sm " + ERReturnsActive()}>
                                        <input type="checkbox" id="ER"/> ER
                                    </label>
                                    <label className={"btn btn-sm " + PRReturnsActive()}>
                                        <input type="checkbox" id="PR"/> PR
                                    </label>
                                    <label className={"btn btn-sm " + TRReturnsActive()}>
                                        <input type="checkbox" id="TR"/> TR
                                    </label>
                                </div>
                            </div>
                            <div className="indices-filters-divider"/>
                            <div className="indices-filter-panel-section">Currency
                                <Dropdown onChange={handleCurrency}>
                                    <Dropdown.Toggle variant="outline-secondary" size="sm">Currency</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {currencyVariants.map(i => (
                                            <div><input type="checkbox" id={i}
                                                        checked={currency.some(item => i === item)}/> {i}</div>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="indices-filters-divider"/>
                            <div className="indices-filter-panel-section">Region
                                <Dropdown onChange={handleRegion}>
                                    <Dropdown.Toggle variant="outline-secondary" size="sm">Region</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {regionVariants.map(i => (
                                            <div><input type="checkbox" id={i}
                                                        checked={region.some(item => i === item)}/> {i}</div>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="reset-button">
                                <button className="btn btn-outline-secondary btn-sm" type="button"
                                        onClick={handleClickRefresh}>
                                    <BsArrowRepeat/>
                                </button>
                            </div>
                        </div>
                        <div className="input-group input-group-md" style={{
                            width: "23%",
                            padding: "6px 7px"
                        }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by index name or ticker"
                                onChange={event => {
                                    setInputNameTickerString(event.target.value)
                                }}
                                onKeyDown={keyPress}
                                aria-describedby="inputGroup-sizing-md"
                                defaultValue={searchNameTicker === null ? "" : searchNameTicker}
                            />
                            <div className="input-group-prepend">
                                <button className="btn btn-light" type="button" onClick={handleClick}>
                                    <BsSearch/>
                                </button>
                            </div>
                        </div>
                        <div style={{height: '85%', padding: "10px"}} className="usual-text ag-theme-webadk ">
                            <AgGridReact
                                headerHeight={30}
                                rowData={indices}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                components={{CellIndexNameLink: CellIndexNameLinkRenderer}}>
                            </AgGridReact>
                        </div>
                    </div>
                </Container>
            }
        </>
    );
};
const CellIndexNameLinkRenderer = (params: ICellRendererParams) => {
    return <a href={"indices/" + params.node.data.indexId}>{params.value}</a>;
};

export default Indices;
