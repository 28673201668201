import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import cover2 from "../../../assets/img/_KAJ0362.png";
import {BsSearch} from "react-icons/bs";

function HomeSearch() {
    const [query, setQuery] = useState('');
    const ENTER_KEYCODE = 13;

    function handleClick(e) {
        e.preventDefault();
        window.location.href = '/dbiq-web/indices?searchNameTicker=' + query;
    }

    function keyPress(e) {
        if (e.keyCode == ENTER_KEYCODE) {
            window.location.href = '/dbiq-web/indices?searchNameTicker=' + query;
        }
    }

    return (
        <Container fluid style={{position: "relative", padding: "20px"}}>
            <div className="d-flex justify-content-center " style={{
                backgroundImage: `url(${cover2})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "240px",
                maxWidth: "1350px",
                display: "flex",
                position: "relative"
            }}>
                <div className="home-search" style={{
                    position: "absolute",
                    top: "31%",
                    left: "100px"
                }}>
                    Search
                </div>
                <div className="input-group input-group-lg" style={{
                    position: "absolute",
                    top: "45%",
                    left: "7%",
                    width: "60%"
                }}>
                    <div className="input-group-prepend">
                        <button className="btn btn-light" type="button"  onClick={handleClick}>
                            <BsSearch/>
                        </button>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Lookup index by index name or ticker"
                        aria-label="Large"
                        onChange={event => setQuery(event.target.value)}
                        onKeyDown={keyPress}
                        aria-describedby="inputGroup-sizing-lg"
                    />
                </div>
            </div>
        </Container>
    )
}

export default HomeSearch;