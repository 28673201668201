import React, {useEffect, useState} from "react";
import pdfLogo from "../../../assets/img/pdf.png";
import notices from "../../../assets/img/22339.png";
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";


function Notices() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [noticesList, setNoticesList] = useState([]);


    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/notices')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setNoticesList(result);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);


    function renderNoticesList(noticesList) {
        return noticesList.map((notice) =>
            <div className="dotted-line">
                <Row className="no-gutters">
                    <Col style={{padding: "0px 7px", maxWidth: "30%"}}>
                        <div style={{float: "left"}} className="home-notices-text-date">{notice.createdDate}</div>
                    </Col>
                    <Col style={{padding: "0px 7px", maxWidth: "70%"}}>
                        <div className="home-notices-text">
                            {notice.noticeText}
                            {(notice.hasFile) ?
                                <a download={notice.fileName} target="_blank"
                                   href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + notice.id + "&documentType=" + notice.documentType}>
                                    <img alt="PDF" src={pdfLogo} width={25} height={25}/>
                                </a>
                                : <></>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }


    return (<div className="column">
            <img style={{width: "100%"}} alt="Notices" src={notices}/>
            <div className="bl-sector">
                <div className="blue-header-home">Notices</div>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "65vh"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <div style={{height: "65vh", overflowY: "auto", width: "100%", overflowX: "hidden"}}>
                        {renderNoticesList(noticesList)}
                    </div>
                }
            </div>
        </div>


    )
}

export default Notices;
