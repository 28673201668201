import React, {useEffect, useState} from "react";
import regulatory from "../../assets/img/9360.png";
import pdfLogo from "../../assets/img/pdf.png";
import HeaderComponent from "../header/HeaderComponent";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import {Modal} from "react-bootstrap";


function Regulatory() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [show, setShow] = useState(false);
    const [docWindowName, setDocWindowName] = useState('');
    const [docWindowType, setDocWindowType] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (type, name) => {
        setShow(true);
        setDocWindowName(name);
        setDocWindowType(type)
    };


    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/documents/regulatoryPlatform')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setDataList(result.regulatoryPlatform);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    function renderLinksWindow(dataList, documentType) {
        return dataList.filter(row => row.documentType === documentType).map((row) =>
            <div style={{padding: "10px"}}>
                <a className="regulatory-text" download={row.documentName} target="_blank"
                   href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + row.id + "&documentType=" + row.documentType}>
                    <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20} height={20}/> {row.documentName}
                </a>
            </div>
        );
    }

    function renderRegulatory(dataList, documentType, name) {
        return dataList.filter(row => row.documentType === documentType).map((row) =>
            <div>
                <div style={{padding: "10px"}}>
                    <a className="regulatory-text" download={row.documentName} target="_blank"
                       href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + row.id + "&documentType=" + row.documentType}>
                        <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20} height={20}/> {name}
                    </a>
                </div>
                <div className="divider"></div>
            </div>
        );
    }

    function renderWindowWithLinks(dataList, documentType, name) {
        const len = dataList.filter(row => row.documentType === documentType).length;
        console.log(len);
        return (<>
                {(len > 0) ?
                    <div>
                        <div style={{padding: "10px"}}>
                            <a className="regulatory-text" onClick={() => handleShow(documentType, name)}>
                                {name}
                            </a>
                        </div>
                        <div className="divider"></div>
                    </div>
                    :
                    <></>
                }
            </>
        );
    }


    return (<>
            {(!isLoaded)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center"
                           style={{height: '500px'}}>
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </Container>
                :
                <>
                    <Container fluid style={{maxWidth: "1350px"}}>
                        <HeaderComponent title={"Regulatory"}/>
                        <Row style={{paddingTop: "15px"}}>
                            <Col style={{padding: "10px 0px"}}>
                                <img style={{width: "100%"}} alt="Regulatory" src={regulatory}/>
                            </Col>
                            <Col style={{padding: "10px 0px"}}>
                                <div className="bl-sector-reg" style={{width: "100%", height: "100%"}}>
                                    <div className="regulatory-boxes">
                                        {renderRegulatory(dataList, "INDEX_ADMINISTRATOR_HANDBOOK", "Index Administrator Handbook")}
                                        {renderRegulatory(dataList, "IOSCO_PRINCIPLES", "IOSCO Principles")}
                                        {renderRegulatory(dataList, "ESG_DISCLOSURES", "ESG Disclosures")}
                                        {renderRegulatory(dataList, "LIST_OF_BENCHMARKS", "List Of Benchmarks")}
                                        {renderWindowWithLinks(dataList, "OTHER_REGPLATFORM_DOCS", "Other Regulatory / Platform Documents")}
                                        {renderWindowWithLinks(dataList, "BENCHMARK_STATEMENTS", "Benchmark Statements")}
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="d-flex align-items-center justify-content-center">
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header>
                                <Modal.Title> <HeaderComponent title={docWindowName}/></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{height: "60vh", overflowY: "scroll"}}>
                                    {renderLinksWindow(dataList, docWindowType)}
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </>
            }
        </>
    )
}

export default Regulatory;
