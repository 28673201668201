import React, {useEffect, useState} from "react";
import IndexPageSpecific from "./IndexPageSpecific";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import NoPageFound from "../NoPageFound/NoPageFound";


function getIndexCompositeKey(url: string) {
    return url.substring(url.lastIndexOf('/') + 1, url.length);
};


function IndexPageSpecificPrivateRedirection() {
    const indexCompositeKey = getIndexCompositeKey(window.location.href.toString());
    const [isPublic, setIsPublic] = useState(false);
    const [indexExists, setIndexExists] = useState(false);
    const [isLoadedP, setIsLoadedP] = useState(false);
    const [isLoadedE, setIsLoadedE] = useState(false);

    function redirect() {
        window.location.href = '/dbiq-web/indices/protected/' + indexCompositeKey;
    }

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + indexCompositeKey + '/isPublic')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsPublic(result);
                    setIsLoadedP(true);
                },
                (error) => {
                    console.error(error);
                }
            )
    }, []);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + indexCompositeKey + '/indexExists')
            .then(res => res.json())
            .then(
                (result) => {
                    setIndexExists(result);
                    setIsLoadedE(true);
                },
                (error) => {
                    console.error(error);
                }
            )
    }, []);


    return ((isLoadedP && isLoadedE) ?
            (indexExists) ?
                (isPublic) ?
                    <IndexPageSpecific/>
                    :
                    <>{redirect()}</>
                :
                <NoPageFound/>
            :
            <Container fluid className="d-flex align-items-center justify-content-center"
                       style={{height: "250px"}}>
                <Spinner animation="border" role="status" variant="primary">
                </Spinner>
            </Container>
    )
}

export default IndexPageSpecificPrivateRedirection;
