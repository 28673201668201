import React, {useEffect, useState} from "react";
import IndexDescription from "./components/IndexDescription";
import IndexNotice from "./components/IndexNotices";
import IndexDetails from "./components/IndexDetails";
import PerformanceMetrics from "./components/PerformanceMetrics";
import MonthlyReturnStatistics from "./components/MonthlyReturnStatistics";
import MonthlyReturns from "./components/MonthlyReturns";
import HistoricalPriceAndVolatility from "./components/HistoricalPriceAndVolatility";
import Row from "react-bootstrap/Row";
import IndexConstituents from "./components/IndexConstituents";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import HeaderComponent from "../header/HeaderComponent";
import NoPageFound from "../NoPageFound/NoPageFound";


function getIndexCompositeKey(url: string) {
    return url.substring(url.lastIndexOf('/') + 1, url.length);
};

function IndexPageSpecific() {
    const indexCompositeKey = getIndexCompositeKey(window.location.href.toString());
    const [documents, setDocuments] = useState([]);
    const [indexData, setIndexData] = useState({});
    const [indexDesc, setIndexDesc] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [indexExists, setIndexExists] = useState(false);
    const [isLoadedE, setIsLoadedE] = useState(false);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + indexCompositeKey)
            .then(res => res.json())
            .then(
                (result) => {
                    setIndexData(result);
                    setDocuments(result.documents);
                    setIndexDesc(result.description);
                    setIsLoaded(true);
                },
                (error) => {
                    console.error(error);
                }
            )
    }, []);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + indexCompositeKey + '/indexExists')
            .then(res => res.json())
            .then(
                (result) => {
                    setIndexExists(result);
                    setIsLoadedE(true);
                },
                (error) => {
                    console.error(error);
                }
            )
    }, []);


    return (
        <div>
            {(!isLoaded || !isLoadedE)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center"
                           style={{height: "250px"}}>
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </Container>
                :
                (indexExists) ?
                    <Container style={{maxWidth: "1350px"}}>
                        <Row style={{paddingBottom: "15px"}}>
                            <HeaderComponent title={indexData['name']}/>
                        </Row>
                        <Row>
                            <IndexDescription description={indexDesc}/>
                            <IndexNotice indexCompositeKey={indexCompositeKey}/>
                        </Row>
                        <Row>
                            <IndexDetails indexCompositeKey={indexCompositeKey} details={indexData}
                                          documents={documents}/>
                            <MonthlyReturnStatistics indexCompositeKey={indexCompositeKey}/>
                            <PerformanceMetrics indexCompositeKey={indexCompositeKey}/>
                            <MonthlyReturns indexCompositeKey={indexCompositeKey}/>
                        </Row>
                        <Row>
                            <HistoricalPriceAndVolatility indexCompositeKey={indexCompositeKey}/>
                            <IndexConstituents indexCompositeKey={indexCompositeKey}/>
                        </Row>
                    </Container>
                    :
                    <NoPageFound/>
            }
        </div>

    )
};

export default IndexPageSpecific;
