import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import CompositeNavbar from "./components/CompositeNavbar/CompositeNavbar";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Indices from "./components/Indices/Indices";
import IndexPageSpecific from "./components/IndexPageSpecific/IndexPageSpecific";
import IndexPageSpecificPrivateRedirection from "./components/IndexPageSpecific/IndexPageSpecificPrivateRedirection";
import TandC from "./components/TandC/TandC";
import Login from "./components/Client/Login";
import NoPageFound from "./components/NoPageFound/NoPageFound";
import Regulatory from "./components/Regulatory/Regulatory";
import Publications from "./components/Publications/Publications";
import Contacts from "./components/Contacts/Contacts";


function App() {
	return (
		<div className="App min-vh-100">
			<BrowserRouter basename="/dbiq-web">
				<CompositeNavbar/>
				<Routes>
						<Route path="/" element={<Home/>}/>
						<Route path="/indices/protected/:indexCompositeKey" element={<IndexPageSpecific/>}/>
						<Route path="/indices/:indexCompositeKey" element={<IndexPageSpecificPrivateRedirection/>}/>
						<Route path="/regulatory" element={<Regulatory/>}/>
						<Route path="/publications" element={<Publications/>}/>
						<Route path="/indices" element={<Indices/>}/>
						<Route path="/contacts" element={<Contacts/>}/>
						<Route path="/login" element={<Login/>}/>
						<Route  path="/*" element={<NoPageFound/>}/>
				</Routes>
				<Footer/>
				<TandC/>
					</BrowserRouter>
		</div>
	);
}

export default App;
