import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import IndexComponentsHeader from "./IndexComponentsHeader";

function styling(data) {
    if (data == null) {
        return {color: "#000000"}
    } else {
        return (data.toFixed(2) >= 0) ? {color: "#0f9d58"} : {color: "red"};
    }
}


function MonthlyReturns(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [startDate, setPriceDate] = useState(0);
    const [returns, setReturns] = useState(Array());

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/monthlyReturns')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setPriceDate(result.currentYear);
                    setReturns(result.monthlyReturns);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    return (
        <Col xs="12" md="6">
            <IndexComponentsHeader title={"Monthly Returns"}/>
            <Row style={{fontSize: "95%"}}>
                <Col>
                    {(!isLoaded)
                        ?
                        <Container fluid className="d-flex align-items-center justify-content-center"
                                   style={{height: "250px"}}>
                            <Spinner animation="border" role="status" variant="primary">
                            </Spinner>
                        </Container>
                        :
                        <Table responsive striped>
                            <thead>
                            <tr>
                                <th>Year</th>
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Apr</th>
                                <th>May</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Aug</th>
                                <th>Sep</th>
                                <th>Oct</th>
                                <th>Nov</th>
                                <th>Dec</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                returns.reverse().slice(0, 5).map((data, index) =>
                                    <tr>
                                        <td className="font-weight-bold">{startDate - index}</td>
                                        {
                                            data.map((data2, index) =>
                                                <td style={styling(data2)}>{data2 == null ? '-' : parseFloat(data2.toFixed(2)).toFixed(2) + '%'}</td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
        </Col>
    )
}

export default MonthlyReturns;
