import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo from '../../assets/img/logo.png'
import dbiq from '../../assets/img/identifier_RETINA.png'
import "../../styles.css";
import Container from "react-bootstrap/Container";


function BrandingNav() {

    return (
        <Container style={{maxWidth: "1350px"}}>
            <Navbar className={"my-nav-flex flex-sm-row flex-column"}>
                <Navbar.Brand className="p-2">
                    <Nav.Link href="https://www.db.com">
                        <a href="https://www.db.com" target="_blank">
                            <img src={dbiq} width="138px" height="17.5px" alt="db logo"/>
                        </a>
                    </Nav.Link>
                    <Nav.Link href="https://index.db.com/dbiq-web"
                              className="text-dbiq-blue">DBIQ</Nav.Link>
                </Navbar.Brand>
                <Nav className="p-2">
                    <Nav.Link href="https://db.com">
                        <div className="brand-text ">db.com</div>
                    </Nav.Link>
                    <Nav.Link href="https://www.db.com/i-b">
                        <div className="brand-text brand-text-border">db.com/i-b</div>
                    </Nav.Link>
                    <Nav.Link href="/dbiq-web/contacts">
                        <div className="brand-text ">Contacts</div>
                    </Nav.Link>
                </Nav>
                <a href="https://www.db.com" target="_blank" className="ml-auto p-2">
                    <img src={logo} width="80px" height="80px" alt="db logo"/>
                </a>
            </Navbar>
        </Container>
    )
}

function Navigation(props) {
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        fetch("/dbiq-web/rest/webdata/isLI")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoggedIn(result);
                },
                (error) => {
                    setError(error);
                    console.error(error);
                }
            )
    }, []);

    return (
        <Container style={{maxWidth: "1350px"}}>
            <Navbar expand={'sm'}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav variant="tabs" activeKey={props.location} style={{width: "100%"}}>
                    <Nav.Item>
                        <Nav.Link href="/dbiq-web/" eventKey='/dbiq-web/'>Home</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href='/dbiq-web/indices' eventKey="/dbiq-web/indices">Indices</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href='/dbiq-web/regulatory' eventKey="/dbiq-web/regulatory">Regulatory</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/dbiq-web/publications"
                                  eventKey="/dbiq-web/publications">Publications</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav variant="tabs">
                    <Nav.Link href={"/dbiq-web/login?prevLink=" + props.location}
                              style={{minWidth: "150px", textAlign: "center"}}
                              disabled={isLoggedIn}>{!isLoggedIn ? "Login" : "You are logged in"}</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    )
}

function CompositeNavbar(props) {
    let location = window.location.pathname;
    return (
        <>
            <BrandingNav/>
            <Navigation location={location}/>
        </>
    );
}

export default CompositeNavbar;
