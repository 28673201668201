import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import IndexComponentsHeader from "./IndexComponentsHeader";


let currentLevel: number = 0;
let allTimeHigh: number = 0;
let allTimeLow: number = 0;
let annualizedVolatility: number = 0;
let riskFreeRate: number = 0;
let sharpeRatio: number = 0;
let sortinoRatio: number = 0;
let annualizedReturn: number = 0;
let maxDrawdown: number = 0;
let dailyReturn: number = 0;
let lastMonthReturn: number = 0;
let threeMonthReturn: number = 0;
let sixMonthReturn: number = 0;
let twelveMonthReturn: number = 0;
let ytdReturn: number = 0;


function styling(data) {
    return (isNaN(data) || data >= 0) ? {color: "black"} : {color: "red"};
}

function formatting(data) {
    if (isNaN(data)) { return 'NA'}
    else {return data.toFixed(2)};
}

function PerformanceMetrics(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [returnData, setReturnData] = useState({});


    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/returnData')
            .then(res => res.json())
            .then(
                (result) => {
                    setReturnData(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    if (Object.keys(returnData).length > 0) {
        currentLevel = parseFloat(returnData['currentLevel'] != null ? returnData['currentLevel'].toFixed(2) : null);
        allTimeHigh = parseFloat(returnData['allTimeHigh'] != null ? returnData['allTimeHigh'].toFixed(2) : null);
        allTimeLow = parseFloat(returnData['allTimeLow'] != null ? returnData['allTimeLow'].toFixed(2) : null);

        annualizedVolatility = parseFloat(returnData['annualizedVolatility'] != null ? returnData['annualizedVolatility'].toFixed(2) : null);
        annualizedReturn = parseFloat(returnData['annualizedReturn'] != null ? returnData['annualizedReturn'].toFixed(2) : null);
        maxDrawdown = parseFloat(returnData['maxDrawdown'] != null ? returnData['maxDrawdown'].toFixed(2) : null);

        riskFreeRate = parseFloat(returnData['riskFreeRate'] != null ? returnData['riskFreeRate'].toFixed(2) : null);
        sharpeRatio = parseFloat(returnData['sharpeRatio'] != null ? returnData['sharpeRatio'].toFixed(2) : null);
        sortinoRatio = parseFloat(returnData['sortinoRatio'] != null ? returnData['sortinoRatio'].toFixed(2) : null);
        dailyReturn = parseFloat(returnData['dailyReturn'] != null ? returnData['dailyReturn'].toFixed(2) : null);

        lastMonthReturn = parseFloat(returnData['lastMonthReturn'] != null ? returnData['lastMonthReturn'].toFixed(2) : null);
        ytdReturn = parseFloat(returnData['ytdReturn'] != null ? returnData['ytdReturn'].toFixed(2) : null);
        threeMonthReturn = parseFloat(returnData['threeMonthReturn'] != null ? returnData['threeMonthReturn'].toFixed(2) : null);
        sixMonthReturn = parseFloat(returnData['sixMonthReturn'] != null ? returnData['sixMonthReturn'].toFixed(2) : null);
        twelveMonthReturn = parseFloat(returnData['twelveMonthReturn'] != null ? returnData['twelveMonthReturn'].toFixed(2) : null);
    }

    return (
        <Col xs='12' md='6' fluid>
            <IndexComponentsHeader title={"Performance Metrics"}/>
            <Row style={{fontSize: "95%"}}>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Current Level:</b><span
                                    style={styling(currentLevel)}>{formatting(currentLevel)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">All Time High:</b><span
                                    style={styling(allTimeHigh)}>{formatting(allTimeHigh)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">All Time Low:</b><span
                                    style={styling(allTimeLow)}>{formatting(allTimeLow)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Annualized Volatility:</b><span
                                    style={styling(annualizedVolatility)}>{formatting(annualizedVolatility)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Annualized Return (%):</b><span
                                    style={styling(annualizedReturn)}>{formatting(annualizedReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Sharpe Ratio:</b><span
                                    style={styling(sharpeRatio)}>{formatting(sharpeRatio)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Sortino Ratio:</b><span
                                    style={styling(sortinoRatio)}>{formatting(sortinoRatio)}</span></p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Risk Free Rate (%):</b><span
                                    style={styling(riskFreeRate)}>{formatting(riskFreeRate)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Max Drawdown (%):</b><span
                                    style={styling(maxDrawdown)}>{formatting(maxDrawdown)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Daily Return (%):</b><span
                                    style={styling(dailyReturn)}>{formatting(dailyReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Month-to-Date Return (%):</b><span
                                    style={styling(lastMonthReturn)}>{formatting(lastMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">3 Month Return (%):</b><span
                                    style={styling(threeMonthReturn)}>{formatting(threeMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">6 Month Return (%):</b><span
                                    style={styling(sixMonthReturn)}>{formatting(sixMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">12 Month Return (%):</b><span
                                    style={styling(twelveMonthReturn)}>{formatting(twelveMonthReturn)}</span>
                                </p>
                                <p className="d-flex"><b className="flex-grow-1">YTD Return (%):</b><span
                                    style={styling(ytdReturn)}>{formatting(ytdReturn)}</span>
                                </p>
                            </div>
                        </Col>
                    </>
                }
            </Row>
        </Col>
    )
}

export default PerformanceMetrics;
