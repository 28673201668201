export function calculateMonthlyPercentages(monthlyReturnsDict) {
    function calculatePercentageChange(value1: number, value2: number) {
        return ((value2 - value1) / value1 * 100);
    }

    let YearlyPercentagesData = Array();
    let monthlyReturnsArray = Array();
    let previousMonth: number;
    let currentMonth: number;
    for (let key in monthlyReturnsDict) {
        monthlyReturnsArray.unshift(monthlyReturnsDict[key]);
    }
    for (let year = 0; year < monthlyReturnsArray.length; year++) {
        let tempPercentages = Array();
        for (let month = monthlyReturnsArray[year].length - 1; month >= 1; month--) {
            currentMonth = monthlyReturnsArray[year][month];
            previousMonth = monthlyReturnsArray[year][month - 1];
            tempPercentages.unshift(calculatePercentageChange(previousMonth, currentMonth));
        }
        if (year < monthlyReturnsArray.length - 1) {
            tempPercentages.unshift(calculatePercentageChange(monthlyReturnsArray[year + 1].slice(-1), monthlyReturnsArray[year][0]));
        }
        YearlyPercentagesData.push(tempPercentages);
    }
    return YearlyPercentagesData;
}
