import React from "react";


function Login() {
    const prevLink = new URLSearchParams(window.location.search).get("prevLink");
    const redirectLink = prevLink == null ? "/dbiq-web/" : prevLink;

    function redirect() {
        window.location.href = redirectLink;
    }

    return (
        <>
            {redirect()}
        </>
    )
}

export default Login;
