import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import Table from "react-bootstrap/Table";
import moment from "moment";
import IndexComponentsHeader from "./IndexComponentsHeader";
import {Button} from "react-bootstrap";
import {CSVLink} from 'react-csv';
import {Disclaimer} from "../../../assets/disclaimer/Disclaimer";


interface ConstituentData {
    currentWeight: number;
    comRic: string;
    priceDate: string;
    indexId: number;
    expiryDate: string;
    comName: string;
}

function randomColorGenerator() {
    return '#' + (Math.random().toString(16) + '0000000').slice(2, 8)
};

function exportFilesHeaders(constituentVersion) {
    if (constituentVersion === 'BondIndexAndIndexPortfolioWeight') {
        return [{label: "ISIN", key: "name"}, {label: "CurrentWeight", key: "weight"}]
    } else if (constituentVersion === 'IC_Swaption') {
        return [{label: "Security", key: "security"}, {label: "Weight", key: "weight"}]
    } else if (constituentVersion === "Fixed Income") {
        return [{label: "ISIN", key: "isin"}, {label: "TICKER", key: "ticker"}, {label: "WEIGHT", key: "weight"},
            {label: "YIELD TO WORST", key: "yieldToWorst"}, {label: "DURATION TO WORST", key: "durationToWorst"},
            {label: "TIME TO WORST", key: "timeToWorst"}]
    } else if (constituentVersion === 'IndexSpecific') {
        return [{label: "ISIN", key: "isin"}, {label: "Coupon", key: "coupon"}, {label: "Maturity", key: "maturity"},
            {label: "Quarter Beginning", key: "quarterBeginning"}, {label: "Rebal Weight", key: "rebalWeight"}]
    } else if (constituentVersion === 'NewIndexOfIndicesWithProtectionBuyerNotional') {
        return [{label: "Date", key: "date"}, {label: "Ticker", key: "ticker"}, {label: "Protection Buyer Notional", key: "protectionBuyerNotional"}]
    } else if (constituentVersion === 'IndexofIndicesLayer1CW') {
        return [{label: "Date", key: "date"}, {label: "Name", key: "name"}, {label: "Current Weight", key: "weight"}]
    } else if (constituentVersion === 'NewIndexOfIndices-1M' || constituentVersion === 'NewIndexOfIndicesNoGraphs-1M'
        || constituentVersion === 'LegacyQueries' || constituentVersion === 'ManualDailyWeight') {
        return [{label: "Name", key: "name"}, {label: "Weight", key: "weight"}]
    } else {
        return [{label: "Futures Contract Code", key: "comName"}, {label: "Commodity", key: "comRic"},
            {label: "Contract Month", key: "expiryDate"}, {label: "Current Weight(%)", key: "currentWeight"}]
    }
};

const ExportReactCSV = ({csvData, fileName, constituentVersion}) => {
    let columnA = exportFilesHeaders(constituentVersion)[0].key;
    let disclaimerMap = Disclaimer.map((data) => {
        let returnObj = {};
        returnObj[columnA] = data;
        return returnObj;
    });
    let dataWithDisclaimer = [...csvData, ...disclaimerMap];
    return (
        <div style={{position: 'absolute', right: '100px', paddingTop: '20px'}}>
            <CSVLink data={dataWithDisclaimer} filename={fileName} headers={exportFilesHeaders(constituentVersion)}>
                <Button variant="outline-secondary" className="ml-3">
                    Export
                </Button>
            </CSVLink>
        </div>

    )
}

function IndexConstituents(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [constituents, setConstituents] = useState<ConstituentData[]>([]);
    const [constituentVersion, setConstituentVersion] = useState<String>('');
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    const viewPortBreakPoint = 414
    const biggerRadius = 190
    const smallerRadius = 150


    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/constituents')
            .then(res => res.json())
            .then(
                (result) => {
                    setConstituents(result.constituents);
                    setConstituentVersion(result.constituentVersion);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    function drawPieChart() {
        return ((constituentVersion === 'NewIndexOfIndices' || constituentVersion === 'HermesCommodities')
            && constituents.length <= 20 && constituents.filter(constituent => constituent.currentWeight < 0).length == 0)
    };

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return() => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimension])

    const ConstituentsTableBody = ({constituentVersion, constituents}) => {
        if (constituentVersion === "Fixed Income") {
            return constituents.map((data) =>
                <tr>
                    <td>{data.isin}</td>
                    <td>{data.ticker}</td>
                    <td>{data.weight}</td>
                    <td>{data.yieldToWorst}</td>
                    <td>{data.durationToWorst}</td>
                    <td>{data.timeToWorst}</td>
                </tr>
            )
        } else if (constituentVersion === 'IndexSpecific') {
            return constituents.map((data) =>
                <tr>
                    <td>{data.isin}</td>
                    <td>{data.coupon}</td>
                    <td>{data.maturity}</td>
                    <td>{data.quarterBeginning}</td>
                    <td>{data.rebalWeight}</td>
                </tr>
            )
        } else if (constituentVersion === 'NewIndexOfIndicesWithProtectionBuyerNotional') {
            return constituents.map((data) =>
                <tr>
                    <td>{data.date}</td>
                    <td>{data.ticker}</td>
                    <td>{data.protectionBuyerNotional}</td>
                </tr>
            )
        } else if (constituentVersion === 'IndexofIndicesLayer1CW') {
            return constituents.map((data) =>
                <tr>
                    <td>{data.date}</td>
                    <td>{data.name}</td>
                    <td>{data.weight}</td>
                </tr>
            )
        } else if (constituentVersion === 'NewIndexOfIndices-1M' || constituentVersion === 'NewIndexOfIndicesNoGraphs-1M'
            || constituentVersion === 'LegacyQueries' || constituentVersion === 'ManualDailyWeight' ||
            constituentVersion === 'BondIndexAndIndexPortfolioWeight' ||constituentVersion === 'IC_Swaption') {
            return constituents.map((data) =>
                <tr>
                    <td>{data.name}</td>
                    <td>{data.weight != null ? data.weight.toFixed(2) : null}</td>
                </tr>
            )
        } else {
            return constituents.map((data) =>
                <tr>
                    <td>{data.comName}</td>
                    <td>{data.comRic}</td>
                    <td>{moment(data.expiryDate, "YYYYMMDD").format("DD MMMM YYYY")}</td>
                    <td>{data.currentWeight != null ? data.currentWeight.toFixed(2) : null}</td>
                </tr>
            )
        }
    };

    const ConstituentsTableHead = ({constituentVersion}) => {
        if (constituentVersion === 'BondIndexAndIndexPortfolioWeight') {
            return <tr>
                <th>ISIN</th>
                <th>Current Weight(%)</th>
            </tr>
        } else if (constituentVersion === 'IC_Swaption') {
            return <tr>
                <th>Security</th>
                <th>Weight</th>
            </tr>
        } else if (constituentVersion === 'Fixed Income') {
            return (
                <tr>
                    <th>ISIN</th>
                    <th>TICKER</th>
                    <th>WEIGHT</th>
                    <th>YIELD TO WORST</th>
                    <th>DURATION TO WORST</th>
                    <th>TIME TO WORST</th>
                </tr>
            )
        } else if (constituentVersion === 'IndexSpecific') {
            return (
                <tr>
                    <th>ISIN</th>
                    <th>Coupon</th>
                    <th>Maturity</th>
                    <th>Quarter Begining</th>
                    <th>Rebal Weight</th>
                </tr>
            )
        } else if (constituentVersion === 'NewIndexOfIndicesWithProtectionBuyerNotional') {
            return <tr>
                <th>Date</th>
                <th>Ticker</th>
                <th>Protection Buyer Notional</th>
            </tr>
        } else if (constituentVersion === 'IndexofIndicesLayer1CW') {
            return <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Current Weight</th>
            </tr>
        } else if (constituentVersion === 'NewIndexOfIndices-1M' || constituentVersion === 'NewIndexOfIndicesNoGraphs-1M'
            || constituentVersion === 'LegacyQueries' || constituentVersion === 'ManualDailyWeight') {
            return (
                <tr>
                    <th>Name</th>
                    <th>Weight</th>
                </tr>
            )
        } else {
            return (
                <tr>
                    <th>Futures Contract Code</th>
                    <th>Commodity</th>
                    <th>Contract Month</th>
                    <th>Current Weight(%)</th>
                </tr>
            )
        }
    };

    return (
        <>
            {
                (isLoaded && constituents.length > 0) ?
                    <Col>
                        <Row>
                            <IndexComponentsHeader title={"Index Constituents"}/>
                            <ExportReactCSV csvData={constituents} fileName={'Index_Constituents.csv'}
                                            constituentVersion={constituentVersion}/>
                        </Row>
                        <Row style={{paddingTop: "25px" }}>
                            <Col>
                                {drawPieChart() ?
                                    <ResponsiveContainer width="100%" height={400}>
                                        <PieChart width={300} height={300}>
                                            <Pie data={constituents} dataKey="currentWeight" nameKey="comName"
                                                 cx="50%" cy="50%" outerRadius={windowDimension.winWidth > viewPortBreakPoint ? biggerRadius : smallerRadius}>
                                                {
                                                    constituents.map((entry, index) => <Cell key={index}
                                                                                             fill={randomColorGenerator()}/>)
                                                }
                                            </Pie>
                                            <Tooltip/>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    :
                                    <></>
                                }
                                <div style={{ maxHeight: `400px`, overflow: "auto" }}>
                                    <Table responsive striped>
                                        <thead>
                                        <ConstituentsTableHead constituentVersion={constituentVersion}/>
                                        </thead>
                                        <tbody>
                                        <ConstituentsTableBody constituentVersion={constituentVersion}
                                                               constituents={constituents}/>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
            }
        </>
    );
}

export default IndexConstituents;
