import React, {useEffect, useState} from "react";

import pdfLogo from "../../../assets/img/pdf.png";
import publications from "../../../assets/img/21937.png";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";


function IndexPublications() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [reportsList, setReportsList] = useState([]);


    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/documents/reports')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setReportsList(result.reports);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);


    const DocsList = ({documents}) => {
        return documents.map((row) =>
            <div style={{width: "100%"}}>
                <a className="home-publications-text" download={row.documentName} target="_blank"
                   href={'/dbiq-web/rest/docsnoticesdata/file?' + "id=" + row.id + "&documentType=" + row.documentType}>
                    <img alt="PDF" src={pdfLogo} width={27} height={27}/>{row.documentName}
                </a>
            </div>
        )
    };

    return (
        <div className="column">
            <img style={{width: "100%"}} alt="Notices" src={publications}/>
            <div className="bl-sector">
                <div className="blue-header-home">Index Publications</div>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "65vh"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <div style={{height: "65vh", width: "100%", overflowY: "auto"}}>
                        <DocsList documents={reportsList}/>
                    </div>
                }
            </div>
        </div>

    )
}

export default IndexPublications;
