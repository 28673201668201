import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import {calculateMonthlyMeasurementsFromPriceData} from "../../../functions/calculateMonthlyMeasurementsFromPriceData";
import {calculateMonthlyPercentages} from "../../../functions/calculateMonthlyPercentages";
import moment from "moment";
import IndexComponentsHeader from "./IndexComponentsHeader";


let startDate: number;
let YearlyPercentagesData = Array();
let averageReturn: number = 0;
let averageNegativeReturn: number = 0;
let averagePositiveReturn: number = 0;
let maximumMonthlyDrawdown: number = 0;


function styling(data) {
    return (isNaN(data) || data >= 0) ? {color: "black"} : {color: "red"};
}

function formatting(data) {
    if (isNaN(data)) { return 'NA'}
    else {return data.toFixed(2)};
}

function AverageReturn(YearlyPercentagesData) {
    let totalMonths = 0;
    let totalPercentages = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (MonthData !== Infinity) {
                totalPercentages = totalPercentages + parseFloat(MonthData);
                totalMonths++;
            }
        })
    });
    return totalPercentages / totalMonths;
}

function NumberOfPositiveMonths(YearlyPercentagesData) {
    let positiveMonths = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) >= 0 && MonthData !== Infinity) {
                positiveMonths++;
            }
        })
    });
    return positiveMonths;
}

function AveragePositiveReturn(YearlyPercentagesData) {
    let returns = 0;
    let positiveMonths = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) >= 0 && MonthData !== Infinity) {
                returns = returns + parseFloat(MonthData);
                positiveMonths++;
            }
        })
    })
    return returns / positiveMonths;
}

function NumberOfNegativeMonths(YearlyPercentagesData) {
    let negativeMonths = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) < 0 && MonthData !== Infinity) {
                negativeMonths++;
            }
        })
    });
    return negativeMonths;
}

function AverageNegativeReturn(YearlyPercentagesData) {
    let returns = 0;
    let negativeMonths = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) < 0 && MonthData !== Infinity) {
                returns = returns + parseFloat(MonthData);
                negativeMonths++;
            }
        })
    });
    return returns / negativeMonths;
}

function MaximumMonthlyDrawdown(YearlyPercentagesData) {
    let maxDrawdown = 0;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) < 0 && MonthData !== Infinity) {
                if (parseFloat(MonthData) < maxDrawdown) {
                    maxDrawdown = parseFloat(MonthData);
                }
            }
        })
    });
    return maxDrawdown;
}

function getYearString(num) {
    let month = '';
    switch (num) {
        case 1:
            month = 'January';
            break;
        case 2:
            month = 'February';
            break;
        case 3:
            month = 'March';
            break;
        case 4:
            month = 'April';
            break;
        case 5:
            month = 'May';
            break;
        case 6:
            month = 'June';
            break;
        case 7:
            month = 'July';
            break;
        case 8:
            month = 'August';
            break;
        case 9:
            month = 'September';
            break;
        case 10:
            month = 'October';
            break;
        case 11:
            month = 'November';
            break;
        case 12:
            month = 'December';
            break;
    }
    return month;
}

function BestMonth(YearlyPercentagesData) {
    let maxDiff = 0;
    let currentYear = startDate;
    let currentMonth = 1;
    let maxYear = startDate;
    let maxMonth = 1;
    YearlyPercentagesData.forEach(YearData => {
        YearData.forEach(MonthData => {
            if (parseFloat(MonthData) > 0 && MonthData !== Infinity) {
                if (parseFloat(MonthData) > maxDiff) {
                    maxDiff = parseFloat(MonthData);
                    maxYear = currentYear;
                    maxMonth = currentMonth;
                }
            }
            currentMonth++;
        });
        currentMonth = 1;
        currentYear--;
    });
    return String(getYearString(maxMonth) + ' ' + String(maxYear)) + ' (' + String(maxDiff.toFixed(2)) + '%)';
}

interface PriceMeasurement {
    priceDate: string;
    level: number;
}

function formatPriceLevels(levels) {
    levels.forEach(function (level) {
        level.priceDate = moment(level.priceDate).format("YYYYMMDD");
    });
    return levels;
}

function MonthlyReturnStatistics(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [levels, setLevels] = useState<PriceMeasurement[]>([]);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/level')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setLevels(formatPriceLevels(result));
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);


    let monthlyReturnsDict = calculateMonthlyMeasurementsFromPriceData(levels);
    startDate = parseInt(Object.keys(monthlyReturnsDict).slice(-1)[0]);
    YearlyPercentagesData = calculateMonthlyPercentages(monthlyReturnsDict);


    if (levels.length > 0) {
        averageReturn = parseFloat(AverageReturn(YearlyPercentagesData).toFixed(2));
        averageNegativeReturn = parseFloat(AverageNegativeReturn(YearlyPercentagesData).toFixed(2));
        averagePositiveReturn = parseFloat(AveragePositiveReturn(YearlyPercentagesData).toFixed(2));
        maximumMonthlyDrawdown = parseFloat(MaximumMonthlyDrawdown(YearlyPercentagesData).toFixed(2));
    }

    return (
        <Col xs="12" md="6">
            <IndexComponentsHeader title={"Monthly Return Statistics"}/>
            <Row style={{fontSize: "95%"}}>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Average Return (%):</b><span
                                    style={styling(averageReturn)}>{formatting(averageReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Number of +ve
                                    Months:</b><span>{NumberOfPositiveMonths(YearlyPercentagesData)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Average Positive Return (%):</b><span
                                    style={styling(averagePositiveReturn)}>{formatting(averagePositiveReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Number of -ve
                                    Months:</b><span>{NumberOfNegativeMonths(YearlyPercentagesData)}</span></p>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div>
                                <p className="d-flex"><b className="flex-grow-1">Average Negative Return (%):</b><span
                                    style={styling(averageNegativeReturn)}>{formatting(averageNegativeReturn)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Best Month
                                    (%):</b><span>{BestMonth(YearlyPercentagesData)}</span></p>
                                <p className="d-flex"><b className="flex-grow-1">Maximum Monthly Drawdown (%):</b><span
                                    style={styling(maximumMonthlyDrawdown)}>{formatting(maximumMonthlyDrawdown)}</span>
                                </p>
                            </div>
                        </Col>
                    </>
                }
            </Row>
        </Col>
    )
}

export default MonthlyReturnStatistics;
