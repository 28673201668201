import React from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles.css';
import Notices from "./components/Notices";
import KeyIndices from "./components/KeyIndices";
import Welcome from "./components/Welcome";
import IndexPublications from "./components/IndexPublications";
import HomeSearch from "./components/HomeSearch";
import Container from "react-bootstrap/Container";
import HeaderComponent from "../header/HeaderComponent";


function Home() {

    return (
        <Container style={{maxWidth: "1350px"}}>
            <HeaderComponent title={"Welcome To DBIQ"}/>
            <Welcome/>
            <HomeSearch/>
            <Row className="no-gutters">
                <Col xs='12' md='4'>
                    <KeyIndices/>
                </Col>
                <Col xs='12' md='4'>
                    <IndexPublications/>
                </Col>
                <Col xs='12' md='4'>
                    <Notices/>
                </Col>
            </Row>
        </Container>
    )
}

export default Home;
