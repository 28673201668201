export const Disclaimer = [
    "Disclaimer: Any calculations or determinations in respect of the Index or any part thereof shall be made by the Index Sponsor acting reasonably and in good faith and shall (save in the case of manifest error) be final, conclusive and binding.",
    "The term \"manifest error\" as used herein shall mean an error that is plain and obvious and can be identified from the results of the calculation or determination itself without: (i) recourse to any underlying data; or (ii) any application or re-application of any formula.",
    "The Index Sponsor makes no express or implied representations or warranties as to (a) the levels at which the Index stands or the underlying constituent data at any particular time on any particular date,",
    "(b) the results to be obtained by any party from the use of the Index or any data included in it for the purposes of issuing securities or carrying out any financial transaction or (c) any other matter.",
    "Where calculations have been made based on information obtained from publicly available source(s), the Index Sponsor has relied on these sources and has not independently verified the information extracted",
    "from these sources and accepts no responsibility or liability in respect thereof. If there is a calculation error the Index Sponsor may restate the Index Level.",
    "",
    "This material was prepared by DBIQ. It is not investment research, and has not been prepared in accordance with legal requirements designed to promote the independence of such.",
    "Any opinions expressed herein may differ from the opinions expressed by other DB departments including the Research Department. DB may engage in transactions in a manner inconsistent with any views discussed herein.",
    "DB trades or may trade as principal in instruments (or related derivatives) linked to the index or indices described in this document, and may have proprietary positions in the instruments (or related derivatives).",
    "DB may make a market in such instruments (or related derivatives), which may in extreme circumstances affect the levels of the index or indices described.",
    "",
    "In no event shall DB have any liability (whether in negligence or otherwise) to any person in connection with such person's use of the Index, unless such use is pursuant to a transaction",
    "between that party and DB relating to the Index and such liability results from wilful default and/or gross negligence on the part of DB.",
    "",
    "The distribution of this document in certain jurisdictions may be restricted by law.",
    "You may not distribute this document, in whole or in part, without our express written permission.",
    "DB SPECIFICALLY DISCLAIMS ALL LIABILITY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL OR OTHER LOSSES OR DAMAGES INCLUDING LOSS OF PROFITS INCURRED BY YOU OR ANY THIRD PARTY THAT MAY ARISE FROM ANY RELIANCE ON THIS DOCUMENT OR FOR THE RELIABILITY, ACCURACY, COMPLETENESS OR TIMELINESS THEREOF.",
    "",
    "Deutsche Bank AG is authorised under German Banking Law (competent authority: European Central Bank and the BaFin, Germany's Federal Financial Supervisory Authority ) and, in the United Kingdom, by the Prudential Regulation Authority.",
    "It is subject to supervision by the European Central Bank and by the BaFin, and is subject to limited regulation in the United Kingdom by the Prudential Regulation Authority and Financial Conduct Authority.",
    "Deutsche Bank AG is a joint stock corporation with limited liability incorporated in the Federal Republic of Germany, Local Court of Frankfurt am Main, HRB No. 30 000;",
    "Branch Registration in England and Wales BR000005 and Registered Address: 21 Moorfields, London EC2Y 9DB. Deutsche Bank AG, London Branch is a member of the London Stock Exchange.",
    "(Details about the extent of our authorisation and regulation by the Prudential Regulation Authority, and regulation by the Financial Conduct Authority are available on request or from www.db.com/en/content/eu_disclosures.htm)",
];