import React, {useEffect, useState} from "react";
import Row from 'react-bootstrap/Row'
import IndexComponentsHeader from "./IndexComponentsHeader";
import {Col} from "react-bootstrap";


function IndexDescription(props) {
    const [indexDesc, setIndexDesc] = useState('');

    useEffect(() => {
        setIndexDesc(props.description);
    }, []);

    return (
        <Col>
            <IndexComponentsHeader title={"Index Description"}/>
            <Row style={{fontSize: "95%"}}>
                <p style={{paddingLeft:"15px"}}><span>{indexDesc}</span></p>
            </Row>
        </Col>
    )
}

export default IndexDescription;
