import React from "react";
import Container from "react-bootstrap/Container";
import HeaderComponent from "../header/HeaderComponent";
import '../../styles.css';
import contacts from "../../assets/img/_MG_2810.png";
import {BsHouseDoorFill, BsFillTelephoneFill, BsEnvelopeFill} from "react-icons/bs";


function Contacts() {
    return (
        <Container fluid style={{maxWidth: "1350px"}}>
            <HeaderComponent title={"Contacts"}/>
            <div style={{paddingTop: "25px"}}>
                <div className="contact-content">
                    <div className="contact-img1">
                        <img style={{width: "100%"}} alt="Notices" src={contacts}/>
                    </div>
                    <div className="contact-img2">
                        <div className="contact-text1">Get in Touch</div>
                        <div className="contact-text2">Here’s how you can reach us...</div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="contact-card">
                        <BsHouseDoorFill className="contacts-icons"/>
                        <div>Address</div>
                        <div className="contact-card-blue" style={{
                            fontWeight: 400,
                            fontSize: '13px',
                            lineHeight: '16px'
                        }}>
                            Deutsche Bank <br/>
                            21 Moorfields <br/>
                            London<br/>
                            EC2Y 9DB
                        </div>
                    </div>
                    <div className="contact-card">
                        <BsEnvelopeFill className="contacts-icons"/>
                        <div>Email</div>
                        <div className="contact-card-blue" style={{
                            fontWeight: 400,
                            fontSize: '18px',
                            lineHeight: '22px'
                        }}>
                            index.data@db.com
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )

}

export default Contacts;