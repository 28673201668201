import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";


function TandC() {
    const [isAccepted, setIsAccepted] = useState(localStorage.getItem('T&C') === 'accepted');
    const [agree, setAgree] = useState(false)
    const handleAccept = () => {
        setIsAccepted(true);
        localStorage.setItem('T&C', 'accepted');
    };
    const checkboxHandler = () => {
        setAgree(!agree);
    }

    return (
        <>
            {(!isAccepted)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center"
                           style={{height: "250px"}}>
                    <Modal
                        show={!isAccepted}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Terms and Conditions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="checkbox" id="agree" onChange={checkboxHandler}/>
                            <label className="ml-1"> I have read and agree to
                                <a href="https://www.db.com/legal-resources/privacy-notice" target="_blank"> Term and
                                    conditions</a>
                            </label>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" disabled={!agree} onClick={handleAccept}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
                :
                <></>}
        </>
    )
}

export default TandC;
