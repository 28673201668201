import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import performers from "../../../assets/img/_MG_0395.png";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";


function KeyIndices() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [indicesList, setIndicesList] = useState([]);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/keyIndices')
            .then(res => res.json())
            .then(
                (result) => {
                    setIndicesList(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    console.error(error);
                }
            )
    }, []);

    const KeyIndicesTable = ({indices}) => {
        return indices.map((data, index) =>
            <tr>
                <td><a href={"indices/" + data.indexId}>{data.indexName}</a></td>
                <td>{data.currentLevel != null ? data.currentLevel.toFixed(2) : " - "}</td>
                <td>{data.oneYearChange != null ? data.oneYearChange.toFixed(2) : " - "}</td>
            </tr>
        )

    };

    const KeyIndicesTableHead = () => {
        return (
            <tr>
                <th>Index</th>
                <th>Current Level</th>
                <th>YTD Return</th>
            </tr>
        )
    };


    return (<div className="column">
            <img style={{width: "100%"}} alt="Notices" src={performers}/>
            <div className="bl-sector">
                <div className="blue-header-home">Key Indices</div>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "65vh"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :
                    <div style={{height: "65vh", width: "100%", overflowY: "auto"}}>
                        <Table responsive striped
                               style={{overflowX: "auto"}}>
                            <thead>
                            <KeyIndicesTableHead/>
                            </thead>
                            <tbody>
                            <KeyIndicesTable indices={indicesList}/>
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>

    )
}

export default KeyIndices;
