import React, {useEffect, useState} from "react";
import pdfLogo from '../../../assets/img/pdf.png';
import HeaderComponent from "../../header/HeaderComponent";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import timeline from "../../../assets/img/image7.png";
import Row from "react-bootstrap/Row";
import {Modal} from "react-bootstrap";
import timelineGraph from "../../../assets/img/Frame248.jpg";
import Spinner from "react-bootstrap/Spinner";


function Welcome() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        fetch('/dbiq-web/rest/docsnoticesdata/documents/regulatoryPlatform')
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setDataList(result.regulatoryPlatform);
                },
                (error) => {
                    console.error(error);
                }
            )
    }, []);

    function renderColumnWelcomeText(dataList, docType) {
        return dataList.filter(row => row.documentType === docType).map((row) =>
            <div className="home-welcome-text">{row.docText}
                <a download={row.documentName} target="_blank"
                   href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + row.id + "&documentType=" + row.documentType}>
                    <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20} height={20}/>
                </a>
            </div>
        );
    }


    function renderWelcome(dataList, column) {
        if (column == 1) {
            return <div>
                {renderColumnWelcomeText(dataList, "INDEX_ADMINISTRATOR_HANDBOOK")}
            </div>
        } else {
            return <div>
                {renderColumnWelcomeText(dataList, "IOSCO_PRINCIPLES")}
                {renderColumnWelcomeText(dataList, "ESG_DISCLOSURES")}
                {renderColumnWelcomeText(dataList, "LIST_OF_BENCHMARKS")}
            </div>
        }
    }


    return (<>
            {(!isLoaded)
                ?
                <Container fluid className="d-flex align-items-center justify-content-center"
                           style={{height: "65vh"}}>
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </Container>
                :
                <Container fluid style={{position: "relative", padding: "20px"}}>
                    <Row>
                        <Col xs='12' md='4'>
                            {renderWelcome(dataList, 1)}
                        </Col>
                        <Col xs='12' md='4'>
                            {renderWelcome(dataList, 2)}
                        </Col>
                        <Col xs='12' md='4'>
                            <div style={{
                                height: "137px",
                                maxWidth: "550px",
                            }}>
                                <div className="d-flex " onClick={handleShow} style={{
                                    backgroundImage: `url(${timeline})`,
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    display: "flex",
                                    position: "relative",
                                    height: "137px",
                                    maxWidth: "550px",
                                    cursor: 'pointer',
                                    top: '20%',
                                    overflow: 'scroll'
                                }}>

                                    <div className="timeline-1">Leading Index platform established...</div>
                                    <div className="timeline-year">1999</div>
                                    <div className="timeline-2">Click here for an overview of the history of DBIQ...
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
            <Container fluid className="d-flex align-items-center justify-content-center" style={{width: "624px"}}>
                <Modal show={show} onHide={handleClose} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title> <HeaderComponent title={"DBIQ Overview"}/></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="timeline-text">Deutsche Bank is a pioneer in Quantitative Investment Solutions
                            with extensive experience in structuring a wide variety of strategies, and has won numerous
                            awards.
                        </div>
                        <Image src={timelineGraph} width="624px" height="888px" fluid/>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    )
}

export default Welcome;
