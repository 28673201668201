import React from "react";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import {Container} from "react-bootstrap";


function Footer() {
    return (
        <Container style={{maxWidth: "1350px"}}>
            <Nav>
                <Nav.Link href="https://www.db.com/legal-resources/privacy-notice" style={{padding: "0px"}}>
                    <div className="footer-bar footer-divider">Terms and conditions</div>
                </Nav.Link>
                <Nav.Link href="https://research.db.com/Research/Help/TermsAndConditions" style={{padding: "0px"}}>
                    <div className="footer-bar footer-divider">Disclaimer</div>
                </Nav.Link>
                <Nav.Link href="/dbiq-web/contacts" style={{padding: "0px"}}>
                    <div className="footer-bar">Contacts</div>
                </Nav.Link>
            </Nav>
            <Navbar expand="lg" variant="light" bg="light">
                <Navbar.Brand href="https://www.db.com" target="_blank">
                    <span style={{
                        color: "#212121",
                        fontSize: "0.725rem"
                    }}>Copyright © {(new Date().getFullYear())} Deutsche Bank AG</span>
                </Navbar.Brand>
            </Navbar>
        </Container>
    )
}

export default Footer;
    