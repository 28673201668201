import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import pdfLogo from "../../../assets/img/pdf.png";
import IndexComponentsHeader from "./IndexComponentsHeader";

let indexName: string = '';
let currency: string = '';
let indexLiveDate: string = '';
let bloombergTicker: string = '';
let reutersRIC: string = '';


function IndexDetails(props) {
    const [indexData, setIndexData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [historicalInceptionDate, setHistoricalInceptionDate] = useState('');
    const [isLoadedHistoricalInceptionDate, setIsLoadedHistoricalInceptionDate] = useState(false);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        setIndexData(props.details);
        setDocuments(props.documents);
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        fetch('/dbiq-web/rest/webdata/' + props.indexCompositeKey + '/historicalInceptionDate')
            .then(res => res.json())
            .then(
                (result) => {
                    setHistoricalInceptionDate(result);
                    setIsLoadedHistoricalInceptionDate(true);
                },
                (error) => {
                    setIsLoadedHistoricalInceptionDate(false);
                    console.error(error);
                }
            )
    }, []);


    if (isLoaded) {
        indexName = indexData['name'];
        currency = indexData['defaultCurrency'];
        indexLiveDate = indexData['launchDate'];
        bloombergTicker = (indexData['bbticker'] === null) ? 'N/A' : indexData['bbticker'];
        reutersRIC = indexData['reuters'];
    }

    const IndexDocuments = ({documents}) => {
        return documents.map((row, index) =>
            <div>
                <a download={row.documentName} target="_blank"
                   href={"/dbiq-web/rest/docsnoticesdata/file?" + "id=" + row.id + "&documentType=" + row.documentType}>
                    <img alt="PDF" className={"mr-2"} src={pdfLogo} width={20} height={20}/>{row.documentName}
                </a>
            </div>
        )
    };

    return (
        <Col xs='12' md='6'>
            <IndexComponentsHeader title={"Index Details"}/>
            <Row style={{fontSize: "95%"}}>
                {(!isLoaded)
                    ?
                    <Container fluid className="d-flex align-items-center justify-content-center"
                               style={{height: "250px"}}>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </Container>
                    :

                    <div style={{paddingLeft:"15px"}}>
                        <p><b>Index Name: </b><span>{indexName}</span></p>
                        <Row>
                            <Col>
                                <p><b>Base Currency: </b><span>{currency}</span></p>
                                <p><b>Historical Inception Date: </b>
                                    {(!isLoadedHistoricalInceptionDate)
                                        ?
                                        <Spinner animation="border" role="status" variant="primary" size={'sm'}/>
                                        :
                                        <span>{moment(historicalInceptionDate, "YYYYMMDD").format("DD MMMM YYYY")}</span>
                                    }
                                </p>
                                <p><b>Index Live
                                    Date: </b><span> {moment(indexLiveDate, "YYYYMMDD").format("DD MMMM YYYY")}</span>
                                </p>
                            </Col>
                            <Col>
                                <p><b>Bloomberg Ticker: </b><span>{bloombergTicker}</span></p>
                                {(reutersRIC !== null) ?
                                    <p><b>Reuters ID: </b><span>{reutersRIC}</span></p>
                                    :
                                    <></>
                                }
                                <p><b>Documents: </b><IndexDocuments documents={documents}/>
                                </p>
                            </Col>
                        </Row>
                    </div>
                }
            </Row>
        </Col>
    )
}

export default IndexDetails;
